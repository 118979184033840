import { shadowStyle } from "utils";
interface Props {
  title: string | number;
  onClick: () => void;
  className?: string;
}

const RectangleButton = ({ title, onClick, className = "" }: Props) => {
  return (
    <div
      onClick={onClick}
      style={shadowStyle}
      className={`bg-primary-purple text-[15px] cursor-pointer font-heebo text-white p-2 px-10 rounded-md ${className}`}
    >
      {title}
    </div>
  );
};

export default RectangleButton;
