import { useMemo, useState } from "react";
import { Answer, TutorialQuestion as TutorialQuestionType } from "types";
import Button from "../Button";
import If from "../If";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import { AiOutlineRight } from "react-icons/ai";
import BubblesPagination from "../BubblesPagination";
import SwipeableViews from "react-swipeable-views";
import Paragraph from "../Paragraph";
import uuid from "react-uuid";

interface Props {
  onAnswer: (answer: Answer) => void;
  loading: boolean;
  question: TutorialQuestionType;
}

const TutorialQuestion = ({ question, onAnswer, loading }: Props) => {
  const [currStep, setCurrStep] = useState(0);
  const step = useMemo(
    () => question?.inner_steps?.[currStep],
    [currStep, question.inner_steps]
  );
  return (
    <QuestionWrapper {...{ headline: step?.headline }}>
      <div className="flex w-full flex-col gap-8 items-center">
        <If cond={!!step?.description}>
          <SwipeableViews
            onChangeIndex={(idx) => setCurrStep(idx)}
            className="w-full cursor-move"
            enableMouseEvents
          >
            {question?.inner_steps?.map((h) => (
              <div className="w-full" key={uuid()}>
                <Paragraph text={h.description} className="mb-4" />
              </div>
            ))}
          </SwipeableViews>
        </If>
        <BubblesPagination
          currStep={currStep}
          stepsAmount={question.inner_steps?.length || 0}
          className="mb-14"
        />
        <Button
          className="w-fit mx-auto"
          rightIcon={<AiOutlineRight />}
          loading={loading}
          title={question.button_text}
          onClick={() => onAnswer(true)}
        />
      </div>
    </QuestionWrapper>
  );
};

export default TutorialQuestion;
