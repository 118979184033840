import { Maybe, Question, QuestionWithAnswer } from "types";
import { logger } from "utils/Logger";
import network from "./network";

export const createChat = async (
  userId: string | number,
  journeyName: Maybe<string>
): Promise<Maybe<Question>> => {
  try {
    const { data } = await network.post("/create_chat", {
      userId,
      journeyName,
    });
    return data;
  } catch ({ message }) {
    logger.error(message);
    return null;
  }
};

export const proceedChatWithAnswer = async (
  answer: QuestionWithAnswer
): Promise<Maybe<Question>> => {
  try {
    const { data } = await network.post("/next_step", answer);
    return data;
  } catch ({ message }) {
    logger.error(message);
    return null;
  }
};
