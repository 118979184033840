import { isDashboardInDevMode } from "bin";
import If from "components/If";
import { Question, SetQuestionType } from "types";

interface Props {
  question: Partial<Question>;
  setQuestion: SetQuestionType<Question>;
  questionMode: "update" | "add";
  setQuestionMode: React.Dispatch<React.SetStateAction<"update" | "add">>;
  setControllerShown: React.Dispatch<React.SetStateAction<boolean>>;
  steps: Partial<Question>[];
}

export const DashboardToolBar = ({
  question,
  setQuestion,
  questionMode,
  setQuestionMode,
  setControllerShown,
  steps,
}: Props) => {
  return (
    <>
      <If cond={isDashboardInDevMode}>
        <div
          onClick={() => {
            setQuestionMode("add");
            setQuestion(() => ({}));
            setControllerShown(false);
          }}
          className="absolute top-3 right-3 z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
        >
          Add
        </div>
      </If>

      <div
        onClick={() => {
          setControllerShown((p) => !p);
        }}
        className="absolute top-3 right-20 z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
      >
        Toggle flow control
      </div>
      <If cond={questionMode === "update"}>
        <If cond={!!steps.find((q) => q.default_step === question.step_id)}>
          <div
            onClick={() => {
              setQuestion(
                () =>
                  steps.find(
                    (q) => q.default_step === question.step_id
                  ) as Partial<Question>
              );
              setControllerShown(false);
            }}
            className="absolute top-3  right-[310px] z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
          >
            Previous
          </div>
        </If>
        <If cond={!!question.default_step}>
          <div
            onClick={() => {
              setQuestion(
                () =>
                  steps.find(
                    (q) => q.step_id === question.default_step
                  ) as Partial<Question>
              );
              setControllerShown(false);
            }}
            className="absolute top-3 right-[430px] z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
          >
            Next
          </div>
        </If>
      </If>
    </>
  );
};
