import { Answer, PopupQuestionType } from "types";
import Container from "../Container";
import Modal from "../Modal";
import PopupQuestionContent from "../PopupQuestionContent";

interface Props {
  question: PopupQuestionType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
  fromDashboard: boolean;
}

const PopupQuestion = ({
  question,
  onAnswer,
  loading,
  fromDashboard,
}: Props) => {
  return (
    <Container>
      {fromDashboard ? (
        <div className="m-auto max-w-[40%] bg-white p-4">
          <PopupQuestionContent
            onConfirm={() => {
              onAnswer(true);
            }}
            loading={loading}
            setIsOpen={() => {}}
            text={question.text}
            title={question.title}
            actions={question.actions}
          />
        </div>
      ) : (
        <Modal isOpen={true}>
          <PopupQuestionContent
            onConfirm={() => {
              onAnswer(true);
            }}
            loading={loading}
            setIsOpen={() => {}}
            text={question.text}
            title={question.title}
            actions={question.actions}
          />
        </Modal>
      )}
    </Container>
  );
};

export default PopupQuestion;
