import {
  Answer,
  MultiQuestion,
  Question,
  QuestionType,
  CustomQuestion as CustomQuestionType,
  SliderQuestion as SliderQuestionType,
  BluTipQuestion as BluTipQuestionType,
  CheckBoxQuestion as CheckBoxQuestionType,
  MultiSliderQuestion as MultiSliderQuestionType,
  TutorialQuestion as TutorialQuestionType,
  InformationQuestion as InformationQuestionType,
  CheckBoxCategoriesQuestion as CheckBoxCategoriesQuestionType,
  RecommendationsQuestion as RecommendationsQuestionType,
  UserQuestionsListQuestion as UserQuestionsListQuestionType,
  BlueTipDotsListQuestion as BlueTipDotsListQuestionType,
  NextQuestion,
  BodyMapQuestion,
  PopupQuestionType,
  ChatPresentationQuestion,
} from "types";
import BodyMap from "./BodyMap/BodyMap";
import CheckBoxCategories from "./Questions/CheckBoxCategories";
import CheckBoxesQuestion from "./Questions/CheckBoxesQuestion";
import CustomQuestion from "./CustomQuestion/CustomQuestion";
import InformationQuestion from "./Questions/InformationQuestion";
import MultiBlueTip from "./Questions/MultiBlueTip";
import MultiSliderQuestion from "./Questions/MultiSliderQuestion";
import NextPage from "./Next/NextPage";
import RecommendationsQuestion from "./Questions/RecommendationsQuestion";
import SimpleQuestion from "./Questions/SimpleQuestion";
import SliderQuestion from "./Questions/SliderQuestion";
import TutorialQuestion from "./Questions/TutorialQuestion";
import PopupQuestion from "./Questions/PopupQuestion";
import ChatPresentation from "./Questions/ChatPresentation";
import UserQuestionListQuestion from "./Questions/UserQuestionListQuestion";
import BlueTipDotsListQuestion from "./Questions/BlueTipDotsListQuestion";

interface Props {
  question: Question;
  onAnswer: (answer: Answer) => void;
  loading: boolean;
  fromDashboard: boolean;
}

const QuestionRenderer = ({
  question,
  onAnswer,
  loading,
  fromDashboard,
}: Props) => {
  switch (question.type) {
    case QuestionType.BlueTipDotsList:
      return (
        <BlueTipDotsListQuestion
          {...{ onAnswer, loading }}
          question={question as BlueTipDotsListQuestionType}
        />
      );
    case QuestionType.Recommendations:
      return (
        <RecommendationsQuestion
          {...{ onAnswer, loading }}
          question={question as RecommendationsQuestionType}
        />
      );
    case QuestionType.UserQuestionList:
      return (
        <UserQuestionListQuestion
          {...{ onAnswer, loading }}
          question={question as UserQuestionsListQuestionType}
        />
      );
    case QuestionType.CheckBoxCategories:
      return (
        <CheckBoxCategories
          {...{ onAnswer, loading }}
          question={question as CheckBoxCategoriesQuestionType}
        />
      );
    case QuestionType.ChatPresentation:
      return (
        <ChatPresentation
          {...{ onAnswer, loading }}
          question={question as ChatPresentationQuestion}
        />
      );
    case QuestionType.Information:
      return (
        <InformationQuestion
          {...{ onAnswer, loading }}
          question={question as InformationQuestionType}
        />
      );
    case QuestionType.BodyMap:
      return (
        <BodyMap
          {...{ onAnswer, loading }}
          question={question as BodyMapQuestion}
        />
      );
    case QuestionType.Tutorial:
      return (
        <TutorialQuestion
          {...{ onAnswer, loading }}
          question={question as TutorialQuestionType}
        />
      );
    case QuestionType.Next:
      return (
        <NextPage
          {...{ onAnswer, loading }}
          question={question as NextQuestion}
        />
      );
    case QuestionType.MultiSlider:
      return (
        <MultiSliderQuestion
          {...{ onAnswer, loading }}
          question={question as MultiSliderQuestionType}
        />
      );
    case QuestionType.CheckBox:
      return (
        <CheckBoxesQuestion
          {...{ onAnswer, loading }}
          question={question as CheckBoxQuestionType}
        />
      );
    case QuestionType.BluTipQuestion:
      return (
        <MultiBlueTip
          {...{ onAnswer, loading }}
          question={question as BluTipQuestionType}
        />
      );
    case QuestionType.Custom:
      return (
        <CustomQuestion
          {...{ onAnswer, loading }}
          question={question as CustomQuestionType}
        />
      );
    case QuestionType.Slider:
      return (
        <SliderQuestion
          {...{ onAnswer, loading }}
          question={question as SliderQuestionType}
        />
      );
    case QuestionType.Popup:
      return (
        <PopupQuestion
          {...{ onAnswer, loading, fromDashboard }}
          question={question as PopupQuestionType}
        />
      );
    case QuestionType.Regular:
      return (
        <SimpleQuestion
          {...{ onAnswer, loading }}
          question={question as MultiQuestion}
        />
      );

    default:
      return (
        <SimpleQuestion
          {...{ onAnswer, loading }}
          question={question as MultiQuestion}
        />
      );
  }
};

export default QuestionRenderer;
