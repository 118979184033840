interface Props {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}
const FormInput = ({ value, onChange, placeholder }: Props) => {
  return (
    <input
      type="text"
      className="input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default FormInput;
