import GraphVis, { GraphEvents } from "react-graph-vis";
import If from "components/If";
import { IGraph } from "types";
import { stringToColor } from "utils";

interface Props {
  events?: GraphEvents;
  graph: IGraph;
  loading: boolean;
}

const Graph = ({ events = {}, graph, loading }: Props) => {
  return (
    <div className="h-screen w-full relative">
      <div className="absolute top-[100px] left-1 z-[20000000] flex flex-col gap-4">
        {/* @ts-ignore */}
        {[...new Set(graph.nodes.map((s) => s.journey_name))].map((j) => (
          <div className="flex gap-2 items-center" key={j}>
            <div
              className={`w-3 h-3`}
              style={{ backgroundColor: stringToColor(j) }}
            />
            <div>{j}</div>
          </div>
        ))}
      </div>
      <If cond={loading}>
        <div className="flex flex-1 items-center justify-center text-xl">
          loading...
        </div>
      </If>
      {!!Object.keys(graph).length && (
        <GraphVis graph={graph} options={options} events={events} />
      )}
    </div>
  );
};

export default Graph;

const options = {
  layout: {
    improvedLayout: true,
    clusterThreshold: 50,
    hierarchical: {
      enabled: true,
      treeSpacing: 700,
      blockShifting: false,
      levelSeparation: 150,
      sortMethod: "directed",
      direction: "UD",
    },
  },
  edges: {
    color: "#000000",
    length: 100,
    smooth: {
      enabled: true,
      type: "cubicBezier",
      forceDirection: "vertical",
    },
    width: 8,
    arrows: {
      to: {
        scaleFactor: 2.4,
      },
    },
  },
  physics: {
    enabled: true,
    hierarchicalRepulsion: {
      centralGravity: 1.0,
      springLength: 10,
      springConstant: 0.0,
      nodeDistance: 420,
      damping: 0.9,
    },
    solver: "hierarchicalRepulsion",
  },
  nodes: {
    font: {
      multi: true,
      size: 30, // px
      color: "white",
    },
    widthConstraint: 300,

    scaling: {
      label: true,
    },
  },
  height: "100%",
};
