import { Maybe } from "types";
import If from "../If";
import MediaWrapper from "../MediaWrapper";
import Paragraph from "../Paragraph";
import Title from "../Title";

interface Props {
  headline?: string;
  description?: Maybe<string>;
  children?: JSX.Element | JSX.Element[];
  paragraphClassName?: string;
  titleClassName?: string;
}

const QuestionWrapper = ({
  headline,
  description,
  children,
  paragraphClassName = "",
  titleClassName = "",
}: Props) => {
  return (
    <MediaWrapper>
      <If cond={!!headline}>
        <Title
          headline={headline as string}
          className={`mb-10 ${titleClassName}`}
        />
      </If>
      <If cond={!!description}>
        <Paragraph
          text={description as string}
          className={paragraphClassName}
        />
      </If>
      <If cond={!description && !headline}>
        <div className="mt-[-10px]" />
      </If>
      {children}
    </MediaWrapper>
  );
};

export default QuestionWrapper;
