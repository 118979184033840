import { useEffect, useMemo, useState } from "react";
import ReactSlider from "react-slider";
import { MultiSliderSingle } from "../types";
import Block from "./Block";
import If from "./If";
import Paragraph from "./Paragraph";
import Title from "./Title";

interface Props extends MultiSliderSingle {
  value: number;
  setValue: (value: number) => void;
  loading?: boolean;
}

const Slider = ({
  min_range,
  max_range,
  min_value,
  max_value,
  mid_value,
  title,
  value,
  description,
  setValue,
  loading,
  blue_title,
  tips,
}: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstVal = useMemo(() => value, []);
  const [showTip, setShowTip] = useState(false);
  const tip = useMemo(() => {
    if (!tips || !Object.keys(tips).length) {
      return false;
    }
    const key = Object.keys(tips).find((k) => {
      const [minVal, MaxVal] = k.split("-");
      return +minVal <= value && value <= +MaxVal;
    });
    if (!key) {
      return false;
    }
    return tips[key];
  }, [tips, value]);

  useEffect(() => {
    if (value !== firstVal) {
      setShowTip(true);
    }
  }, [value, firstVal]);

  return (
    <div
      className="
    flex
    flex-col
    gap-6"
    >
      <If cond={!!blue_title}>
        <Block text={blue_title as string} className="mb-1" />
      </If>
      <If cond={!!title}>
        <Title headline={title as string} />
      </If>
      <If cond={!!description}>
        <Paragraph text={description as string} className="mb-1 mt-1" />
      </If>
      <ReactSlider
        className="
        w-full
        cursor-pointer
        self-center
        "
        thumbClassName="customSlider-thumb"
        trackClassName="customSlider-track"
        disabled={loading}
        renderThumb={(props) => (
          <div {...props}>
            <div
              className={`absolute z-40 w-16 h-16
              top-[-20px]
              left-[-20px]
              rounded-full cursor-pointer
              ${loading && "cursor-not-allowed"}
              `}
            />
          </div>
        )}
        min={+min_range ?? 0}
        max={+max_range ?? 10}
        step={1}
        value={value}
        onChange={(e: number) => {
          setValue(e);
        }}
      />
      <div className="flex justify-between text-xl text-primary-blue mt-1">
        <If cond={!!min_value}>
          <div className="text-sm max-w-[110px] whitespace-pre-line break-words">
            {min_value}
          </div>
        </If>
        <If cond={!!mid_value}>
          <div className="text-sm max-w-[110px] whitespace-pre-line break-words">
            {mid_value}
          </div>
        </If>
        <If cond={!!max_value}>
          <div className="text-sm max-w-[110px] whitespace-pre-line break-words">
            {max_value}
          </div>
        </If>
      </div>
      <If cond={!!tip && showTip}>
        <div
          className="w-full rounded p-3 my-2 bg-gray-highlight  whitespace-pre-line
    break-words"
        >
          {tip}
        </div>
      </If>
    </div>
  );
};

export default Slider;
