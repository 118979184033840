import { useCallback, useState } from "react";
import { useImmer } from "use-immer";
import { Answer, BodyMapQuestion } from "types";
import NextButton from "../NextButton";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import { bodyAnt, bodyPost } from "./data";
import uuid from "react-uuid";
import { motion } from "framer-motion";
import { FiRotateCcw } from "react-icons/fi";

interface Props {
  question: BodyMapQuestion;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
const BodyMap = ({ question, onAnswer, loading }: Props) => {
  const [parts, setParts] = useImmer<Record<string, number>>({});
  const [isFront, setIsFront] = useState(true);
  const [animationState, setAnimationState] = useState(true);
  const onClickSvg = useCallback(
    (name: string) => {
      const newParts = { ...parts };
      switch (newParts[name]) {
        case 1:
          setParts((parts) => {
            parts[name] += 1;
          });
          return;
        case 2:
          setParts((parts) => {
            delete parts[name];
          });
          return;

        default:
          setParts((parts) => {
            parts[name] = 1;
          });
          return;
      }
    },
    [parts, setParts]
  );

  const getClass = (selected: number | undefined) => {
    const baseClass = "stroke-[#206E8C] absolute left-[50%]";
    switch (selected) {
      case 1:
        return `fill-secondary-purple ${baseClass}`;
      case 2:
        return `fill-primary-blue ${baseClass}`;
      default:
        return `fill-[#EFF0FC] ${baseClass}`;
    }
  };

  const { headline, description } = question;

  return (
    <QuestionWrapper
      {...{
        headline,
        description,
        paragraphClassName: "select-none",
        titleClassName: "select-none",
      }}
    >
      <div className="flex justify-between select-none items-center">
        <div>
          <div className="flex gap-1 items-center">
            <span className="bg-secondary-purple rounded-full w-3 h-3" />
            Mild pain (click)
          </div>
          <div className="flex gap-1 items-center">
            <div className="bg-primary-blue rounded-full w-3 h-3" />
            Severe pain (click x2)
          </div>
        </div>
        <div className="flex-col justify-center items-center">
          <motion.div
            className="w-fit h-fit mx-auto"
            initial={{
              rotate: 0,
            }}
            animate={{
              rotate: isFront ? 0 : 180,
              transition: {
                ease: "linear",
              },
            }}
          >
            <FiRotateCcw
              color="#206E8C"
              className="cursor-pointer"
              size={30}
              onClick={() => setIsFront((p) => !p)}
            />
          </motion.div>
          {isFront ? "See back" : "See front"}
        </div>
      </div>

      <div>
        {
          <motion.div className="h-[400px] w-[207px] mx-auto" key={uuid()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375.42 832.97">
              <g id="Calque_2" data-name="Calque 2">
                <g id="body_face">
                  {(isFront ? bodyAnt : bodyPost).map((membre, index) => (
                    <motion.path
                      key={`${membre}__${index}`}
                      className={`${getClass(parts[membre.name])}`}
                      onClick={() => onClickSvg(membre.name)}
                      id={membre.id}
                      d={membre.d}
                      initial={{
                        opacity: 0,
                        rotateY: 0,
                      }}
                      transition={{
                        duration: animationState === isFront ? 0 : 0.7,
                      }}
                      onAnimationComplete={() => setAnimationState(isFront)}
                      animate={{
                        opacity: 1,
                        rotateY: 360,
                      }}
                    />
                  ))}
                </g>
              </g>
            </svg>
          </motion.div>
        }
      </div>

      <NextButton
        onClick={() => onAnswer(parts)}
        className="ml-auto mt-10"
        loading={loading}
      />
    </QuestionWrapper>
  );
};

export default BodyMap;
