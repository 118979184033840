import uuid from "react-uuid";

export const questionMock1 = {
  journey_id: 1,
  component_id: 12,
  step_id: 2,
  type: "multi_choices",
  step_number: 2,
  number_of_steps: 5,
  headline: "How old are you?",
  description: null,
  answers: [
    { text: "Under 45", id: 1 },
    { text: "46-55", id: 2 },
    { text: "56-65", id: 3 },
    { text: "66-75", id: 4 },
    { text: "75+", id: 5 },
  ],
  default_step: 3,
  journey_name: "onboarding",
  chat_id: "76c7da2c-72c6-4830-a355-df75c6eaaa69",
};
export const questionMock2 = {
  journey_id: 1,
  component_id: 25,
  step_id: 5,
  type: "slider",
  step_number: 4,
  number_of_steps: 5,
  headline:
    "How much distress you are experiencing in the past few days, including today.",
  description:
    "Distress is an unpleasant experience of a mental, physical, social or spiritual nature. It can affect the way you think, feel, or act. Distress may make it harder to cope with a severe illness, its symptoms, or its treatment.",
  min_value: "No distress",
  max_value: "Extreme distress",
  min_range: 0,
  max_range: 10,
  default_step: 10,
  journey_name: "onboarding",
  tips: {
    "0-3": `sorry to hear that remember to eat`,
    "4-7": "i understand",
    "8-10": "happy to hear that",
  },
};

export const questionMock3 = {
  journey_id: 1,
  chat_id: "1a1a1a11a",
  component_id: 30,
  step_id: 5,
  type: "multi_blue_tip",
  step_number: 5,
  number_of_steps: 5,
  bullets: [
    {
      bullet: "Now",
      headline: "We are about to ask you all kinds of questions",
      text: "Before we do, we want to make it clear that this is your information and that we will never share with anyone.",
      id: 1,
    },
    {
      bullet: "After",
      headline: "We’ll suggest the right journeys for you",
      text: "Once we will know you a bit better we will present different journeys to you. Some journeys will be about sleep, pain, fear, what to share and not share with your friends and family... and more.",
      id: 2,
    },
    {
      bullet: "Also",
      headline: "Other journeys as well",
      text: "Other journeys will be about how to leave the best legacy behind, and there will be some about important medical junctions.",
      id: 3,
    },
  ],
  default_step: 6,
  journey_name: "onboarding",
};

export const questionMock4 = {
  journey_id: 1,
  component_id: 27,
  step_id: 5,
  type: "checkbox_group",
  step_number: 5,
  number_of_steps: 5,
  headline:
    "Below are the most common physical symptoms people describe. Do any of these symptoms disrupt your daily life to some extent?",
  answers: [
    {
      title: "Pain",
      text: "Unpleasant feeling, caused by disruption to nerves as a result of illness or injury.",
      id: 1,
    },
    {
      title: "Breathlessness",
      text: "The feeling of not being able to take a deep breath. Hunger for air.",
      id: 2,
    },
    {
      title: "Fatigue",
      text: "lack of energy, as if I am running out of my battery",
      id: 3,
    },
    {
      title: "Constipation",
      text: "Infrequency or difficulty in passing feces",
      id: 4,
    },
    {
      title: "Nausea or vomiting",
      text: "Uneasiness of the stomach that often comes before vomiting.",
      id: 5,
    },
    {
      title: "Sleep disturbance",
      text: "Not maintaining a good night sleep, and/or waking up in the morning not feeling rested.",
      id: 6,
    },
    {
      title: "Exacerbated appetite",
      text: "Having a feeling of hunger most of the time, or eating much more than I previously ate.",
      id: 7,
    },
    {
      title: "Confusion",
      text: "Experience of the world being unclear. Inability to think as clearly as I normally would.",
      id: 8,
    },
    {
      title: "Other",
      text: "",
      edit: true,
      id: 9,
    },
  ],
  default_step: 10,
  journey_name: "onboarding",
};

export const questionMock5 = {
  chat_id: "1a11a1a11",
  journey_id: 1,
  component_id: 112,
  step_id: 5,
  type: "multi_slider",
  headline:
    "How much distress you are experiencing in the past few days, including today.",
  sliders: [
    {
      id: 1,
      title: "Distress",
      description:
        "Distress is an unpleasant experience of a mental, physical, social or spiritual nature. It can affect the way you think, feel, or act. Distress may make it harder to cope with a severe illness, its symptoms, or its treatment.",
      min_value: "No distress",
      mid_value: "Average distress",
      max_value: "Extreme distress",
      min_range: 0,
      max_range: 10,
      tips: {
        "0-3": `sorry to hear that remember to eat`,
        "4-7": "i understand",
        "8-10": "happy to hear that",
      },
    },
    {
      id: 2,
      title: "Work",
      min_value: "Not Enough",
      max_value: "Too Much",
      min_range: 0,
      max_range: 10,
    },
    {
      id: 3,
      description:
        "Distress is an unpleasant experience of a mental, physical, social or spiritual nature. It can affect the way you think, feel, or act. Distress may make it harder to cope with a severe illness, its symptoms, or its treatment.",
      min_value: "No distress",
      mid_value: "Average distress",
      max_value: "Extreme distress",
      min_range: 0,
      max_range: 10,
    },
    {
      id: 4,
      title: "Health",
      min_value: "Not Enough",
      max_value: "Too Much",
      min_range: 0,
      max_range: 10,
    },
  ],
  default_step: 6,
  journey_name: "onboarding",
};

const questionMock6 = {
  chat_id: "1a1a1a1aa",
  journey_id: 1.0,
  component_id: 201.0,
  step_id: 1.0,
  type: "next",
  headline: "Distress",
  description:
    "Distress is an unpleasant experience of a mental, physical, social or spiritual nature. It can affect the way you think, feel, or act. Distress may make it harder to cope with a severe illness, its symptoms, or its treatment.",
  button_text: "Next",
  default_step: 2.0,
  journey_name: "symptom_management",
};

const questionMock7 = {
  chat_id: "1a1a1a1a1",
  journey_id: 1,
  component_id: 29,
  step_id: 5,
  type: "open_text_category",
  step_number: 5,
  number_of_steps: 5,
  headline: "Add a question",
  categories: [
    {
      title: "Improving my general wellbing",
      id: 1,
      icon: "heartIcon",
    },
    {
      title: "Managing my symptoms",
      id: 2,
      icon: "sunHoldingIcon",
    },
    {
      title: "Understanding my medical information",
      id: 3,
      icon: "checkListIcon",
    },
    {
      title: "What should I do while at home",
      id: 4,
      icon: "homeIcon",
    },
    {
      title: "My hospitalization alternatives",
      id: 5,
      icon: "bedIcon",
    },
  ],
  actions: [
    {
      type: "another_one",
      text: "Save and add another question",
    },
    {
      type: "review",
      text: "Save and review list",
    },
  ],
  default_step: 10,
  journey_name: "conversation with the doctor",
};
const questionMock8 = {
  journey_id: 1,
  component_id: 12,
  step_id: 6,
  type: "tutorial",
  inner_steps: [
    {
      headline: "Welcome to Epilog",
      description:
        "This system is a journey companion for people who are dealing with complex medical diagnosis, and their families. This system will try to help you make better decisions dealing with illness, reduce the level of stress, and improve the quality of your life.",
    },

    {
      headline: "Illness is part of the human condition",
      description:
        "And, with the progress of medical science and knowledge, we live longer.",
    },

    {
      headline: "We are here to help",
      description:
        "Unfortunately, being ill comes with diminished quality of life. The good news is that there are ways to make things much better.",
    },

    {
      headline: "We want to know you better",
      description:
        "In our joint journey we will suggest some steps that we believe will make you better-off. It is not going to be quick, but everything we know suggests that it will be worth the effort.",
    },
  ],
  button_text: "Let’s start",
  default_step: 3,
  journey_name: "onboarding",
};

export const questionMock9 = {
  journey_id: 1,
  component_id: 12,
  step_id: 2,
  type: "body_map",
  step_number: 2,
  number_of_steps: 5,
  headline: "Where is your pain?",
  description: "select the hurting body parts",
  default_step: 3,
  journey_name: "onboarding",
  chat_id: "76c7da2c-72c6-4830-a355-df75c6eaaa69",
};

const questionMock10 = {
  chat_id: "1a1a1a1aa",
  journey_id: 1.0,
  component_id: 201.0,
  step_id: 1.0,
  type: "information",
  headline: "Thank you",
  description: `Sharing is a complex issue. We often share about ourselves just because we know people around us want to know what is going on with us. We are not always aware of the value of sharing, but sharing can add resilience to our life, as well as add support and empathy. Sharing is a complex issue. We often share about ourselves just because we know people around us want to know what is going on with us. We are not always aware of the value of sharing, but sharing can add resilience to our life, as well as add support and empathy. `,
  identification_description:
    "Based on your responses, our experts identified the following:",
  identifications: [
    {
      text: "Relative to others, sharing something personal about yourself with others makes you feel generally better",
      icon: "heartIcon",
    },
    {
      text: "Your sharing modality of choice is [b]*in person[b]",
      icon: "checkListIcon",
    },
    {
      text: "Your level of comfort sharing freely is higher than most people",
      icon: "homeIcon",
    },
    {
      text: "Your preference for allowing other people to take an active role in important decisions is above average",
      icon: "sunHoldingIcon",
    },
  ],
  button_text: "See our recommendations",
  default_step: 2.0,
  journey_name: "onboarding",
};

export const questionMock11 = {
  journey_id: 1,
  component_id: 27,
  step_id: 5,
  type: "checkbox_categories",
  step_number: 5,
  number_of_steps: 5,
  headline:
    "To help you get going, our experts prepared a basic  list of questions. Feel free to select a few questions from our list, and add your own.",
  categories: [
    {
      title: "Managing my symptoms",
      icon: "heartIcon",
      id: uuid(),
      checkboxes: [
        {
          text: "What should I do to make my pain more manageable?",
          id: uuid(),
        },
        {
          text: "How should I manage my sleep?",
          id: uuid(),
        },
        {
          text: "How can you help me manage my anxiety?",
          id: uuid(),
        },
      ],
    },
    {
      title: "Improving my general  wellbeing",
      icon: "heartIcon",
      id: uuid(),
      checkboxes: [
        {
          text: "Is there anything I can do in terms of my life style / diet / habits /  to control the pace of advancement of my disease?",
          id: uuid(),
        },
        {
          text: "Is there anything I can do in terms of life style / diet / habits/  to improve my wellbeing alongside fighting the disease?",
          id: uuid(),
        },
      ],
    },
    {
      title: "Understanding my medical information",
      icon: "heartIcon",
      id: uuid(),
      checkboxes: [
        {
          text: "Where can I get additional reliable information about my condition?",
          id: uuid(),
        },
        {
          text: "What should I expect in terms of disease advancement in the upcoming weeks/months/years?",
          id: uuid(),
        },
        {
          text: "What information is critical for me to know in order to decide on  additional / alternative treatments?",
          id: uuid(),
        },
        { text: "When can we talk about my care goals?", id: uuid() },
      ],
    },
  ],
  actions: [
    {
      type: "add_question",
      text: "Add a question",
      leftIcon: "plus",
    },
    {
      type: "prepare_for_meeting",
      text: "Prepare for the meeting",
    },
  ],
  default_step: 10,
  journey_name: "onboarding",
};
const questionMock12 = {
  type: "recommendations",
  headline: "aaaaa",
  title: "aaaaa",
  description: "aaaa",
  questions: [
    {
      id: 1,
      title: "aa",
      description: "aaaa",
      answers: [
        {
          text: "yes",
          id: 1,
          suggest: true,
        },
        {
          text: "no",
          id: 1,
        },
      ],
    },
    {
      title: "aa",
      id: 2,
      description: "aaaa",
      answers: [
        {
          text: "yes",
          id: 1,
          suggest: true,
        },
        {
          text: "no",
          id: 1,
        },
      ],
    },
  ],
  feedback: "text",
  actions: [
    {
      id: 1,
      text: "aaaa",
      step_id: "aa22",
      left_icon: "",
    },
  ],
};

const questionMock13 = {
  journey_id: 1,
  component_id: 12,
  step_id: 2,
  type: "popup",
  step_number: 2,
  text: "aaaa",
  title: "aa",
  number_of_steps: 5,
  actions: [
    {
      id: 1,
      text: "ok",
      step_id: "aa22",
      leftIcon: "",
    },
    {
      id: 1,
      text: "aaaa",
      step_id: "aa22",
      leftIcon: "",
    },
  ],
  default_step: 3,
  journey_name: "onboarding",
  chat_id: "76c7da2c-72c6-4830-a355-df75c6eaaa69",
};

const questionMock14 = {
  type: "chat_presentation",
  headline: "Prepare…",
  description: "Understanding..",
  sections: [
    {
      title: "Care goals",
      description: "aaaa",
      chat: [
        {
          from: "Doctor",
          text: "aaaa",
        },
        {
          from: "You",
          text: "aaaa",
        },
      ],
    },
  ],
};
export const questionMock15 = {
  journey_id: 1,
  component_id: 27,
  step_id: 5,
  type: "user_input_summary",
  step_number: 5,
  number_of_steps: 5,
  headline:
    "To help you get going, our experts prepared a basic  list of questions. Feel free to select a few questions from our list, and add your own.",
  categories: [
    {
      title: "Managing my symptoms",
      icon: "heartIcon",
      id: uuid(),
      data: [
        {
          text: "What should I do to make my pain more manageable?",
          id: uuid(),
        },
        {
          text: "How should I manage my sleep?",
          id: uuid(),
        },
        {
          text: "How can you help me manage my anxiety?",
          id: uuid(),
        },
      ],
    },
    {
      title: "Improving my general  wellbeing",
      icon: "heartIcon",
      id: uuid(),
      data: [
        {
          text: "Is there anything I can do in terms of my life style / diet / habits /  to control the pace of advancement of my disease?",
          id: uuid(),
        },
        {
          text: "Is there anything I can do in terms of life style / diet / habits/  to improve my wellbeing alongside fighting the disease?",
          id: uuid(),
        },
      ],
    },
    {
      title: "Understanding my medical information",
      icon: "heartIcon",
      id: uuid(),
      data: [
        {
          text: "Where can I get additional reliable information about my condition?",
          id: uuid(),
        },
        {
          text: "What should I expect in terms of disease advancement in the upcoming weeks/months/years?",
          id: uuid(),
        },
        {
          text: "What information is critical for me to know in order to decide on  additional / alternative treatments?",
          id: uuid(),
        },
        { text: "When can we talk about my care goals?", id: uuid() },
      ],
    },
  ],
  actions: [
    {
      type: "add_question",
      text: "Add a question",
      leftIcon: "plus",
    },
    {
      type: "edit_list",
      text: "Edit list",
      leftIcon: "pencil",
    },
  ],
  default_step: 10,
  journey_name: "onboarding",
};

const questionMock16 = {
  type: "blue_tip_dots_list",
  headline: "Prepare…",
  description: "Understanding..",
  sections: [
    {
      title: "Care goals",
      description: "aaaa",
      payload: [
        {
          title: "Doctor",
          dots: [
            {
              text: "hifdmfldmfldfm dfdfdfdf Id df fd dfd fd sd ds ds ds ds \n sd",
              id: uuid(),
            },
            {
              text: "bye",
              id: uuid(),
            },
          ],
        },
        {
          title: "Doctor",
          dots: [
            {
              text: "hi",
            },
          ],
        },
      ],
    },
  ],
};

export const mocks = [
  questionMock1,
  questionMock2,
  questionMock3,
  questionMock4,
  questionMock5,
  questionMock6,
  questionMock7,
  questionMock8,
  questionMock9,
  questionMock10,
  questionMock11,
  questionMock12,
  questionMock13,
  questionMock14,
  questionMock15,
  questionMock16,
];
