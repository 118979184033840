// TODO check responses and tokens
import axios from "axios";
import Cookies from "js-cookie";

const network = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "ngrok-skip-browser-warning": true,
  },
});

const getToken = () => Cookies.get("accessToken");

network.interceptors.request.use((config) => {
  config.headers!.authorization = `bearer ${getToken()}`;
  return config;
});

// network.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const status = error?.response?.status;
//     const originalRequest = error.config;

//     if (status === 408) {
//       try {
//         await network.post("/api/v1/auth/token", {
//           token: Cookies.get("refreshToken"),
//         });
//         const data = await network(originalRequest);
//         return data;
//       } catch (e) {
//         throw e;
//       }
//     } else {
//       throw error;
//     }
//   }
// );

export default network;
