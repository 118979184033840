import { shadowStyle } from "utils/consts";
import { CustomAnswer, CustomQuestion as CustomQuestionType } from "types";
import Button from "../Button";
import DropDown from "../DropDown";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import { CUSTOM_QUESTIONS_ICONS } from "./CustomQuestionUtils";
import { useEffect, useState } from "react";

interface Props {
  onAnswer: (answer: CustomAnswer) => void;
  question: CustomQuestionType;
  loading: boolean;
}
const CustomQuestion = ({ onAnswer, question, loading }: Props) => {
  const [category, setCategory] = useState(() => {
    const firstItem = question?.categories?.[0];
    return {
      ...firstItem,
      ...CUSTOM_QUESTIONS_ICONS?.[
        firstItem?.icon as keyof typeof CUSTOM_QUESTIONS_ICONS
      ],
    };
  });
  const [text, setText] = useState("");
  useEffect(() => {
    if (!!question?.categories?.length) {
      const firstItem = question?.categories?.[0];
      setCategory({
        ...firstItem,
        ...CUSTOM_QUESTIONS_ICONS[
          firstItem.icon as keyof typeof CUSTOM_QUESTIONS_ICONS
        ],
      });
    }
  }, [question.categories]);

  return (
    <QuestionWrapper
      {...{
        headline: question.headline,
        thumbnail: question.header,
      }}
    >
      <DropDown
        loading={loading}
        value={category}
        setValue={setCategory as any}
        items={question.categories?.map((category) => ({
          ...category,
          ...CUSTOM_QUESTIONS_ICONS[
            category.icon as keyof typeof CUSTOM_QUESTIONS_ICONS
          ],
        }))}
      />
      <div>
        <textarea
          style={shadowStyle}
          className={`textarea textarea-bordered bg-white w-full h-40 text-xl resize-none ${
            loading && "cursor-not-allowed"
          }`}
          placeholder="Type here"
          value={text}
          onChange={(e) => {
            if (!loading) {
              setText(e.target.value);
            }
          }}
        />
      </div>
      <div>
        {question.actions?.map(({ text, step_id, type }) => (
          <Button
            {...{ loading }}
            key={type}
            className="w-10/12 mx-auto my-5"
            secondary={type !== "review"}
            title={text}
            onClick={() => {
              if (type === "review") {
                const { id, title } = category;
                onAnswer({ category: { id, title }, step_id, text });
              }
            }}
          />
        ))}
      </div>
    </QuestionWrapper>
  );
};

export default CustomQuestion;
