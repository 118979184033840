import { useEffect } from "react";
import { GiCheckMark } from "react-icons/gi";
import {
  Answer,
  CheckBoxCategoriesAnswerSingle,
  CheckBoxCategoriesQuestion as CheckBoxCategoriesType,
} from "types";
import { useImmer } from "use-immer";
import { GREEN_HIGHLIGHT, icons } from "utils";
import Accordion from "../Accordion";
import Button from "../Button";
import { CUSTOM_QUESTIONS_ICONS } from "../CustomQuestion/CustomQuestionUtils";
import If from "../If";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: CheckBoxCategoriesType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
interface StateCheckBox extends CheckBoxCategoriesAnswerSingle {
  checked: boolean;
}
const CheckBoxCategories = ({ question, onAnswer, loading }: Props) => {
  const [state, setState] = useImmer<StateCheckBox[]>(() => {
    const newState = [] as StateCheckBox[];
    for (let i = 0; i < question?.categories?.length; i++) {
      question?.categories[i].checkboxes.forEach((checkbox) => {
        newState.push({
          ...checkbox,
          checked: false,
          category: question?.categories[i].id,
        });
      });
    }
    return newState;
  });

  useEffect(() => {
    const newState = [] as StateCheckBox[];
    for (let i = 0; i < question?.categories?.length; i++) {
      question?.categories[i].checkboxes.forEach((checkbox) => {
        newState.push({
          ...checkbox,
          checked: false,
          category: question?.categories[i].id,
        });
      });
    }
    setState(newState);
  }, [question?.categories, setState]);
  return (
    <QuestionWrapper
      {...{ headline: question.headline, description: question.description }}
    >
      <div>
        {question?.categories?.map((category) => {
          const icon = category?.icon;
          const iconData =
            CUSTOM_QUESTIONS_ICONS[icon as keyof typeof CUSTOM_QUESTIONS_ICONS];
          const questionsCount = state.reduce(
            (acc, curr) =>
              curr.checked && curr.category === category.id ? acc + 1 : acc,
            0
          );
          return (
            <Accordion
              title={category?.title}
              icon={iconData?.icon}
              iconBg={iconData?.bg}
              className="mb-5"
              key={category?.id}
              customElement={
                <If cond={questionsCount > 0}>
                  <div className="flex">
                    <div className="bg-primary-purple w-6 h-6 flex  rounded-full items-center justify-center text-gray-highlight">
                      <div>{questionsCount || ""}</div>
                    </div>
                    <div className="text-primary-purple ml-1">
                      question{questionsCount > 1 ? "s" : ""} added
                    </div>
                  </div>
                </If>
              }
            >
              <div className="flex flex-col gap-2 relative">
                {category?.checkboxes?.map(({ text, id }) => {
                  const checked = !!state.find((c) => c.id === id && c.checked);

                  return (
                    <>
                      <div
                        className="select-none p-4 bg-gray-highlight rounded-[15px] flex gap-2"
                        key={id}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (loading) return;
                          setState((prev) => {
                            const idx = prev.findIndex((c) => c.id === id);
                            prev[idx].checked = !prev[idx].checked;
                          });
                        }}
                      >
                        <div>
                          <div className="relative">
                            <If cond={checked}>
                              <div className="absolute top-[-3px] left-[11px]">
                                <GiCheckMark
                                  size={32}
                                  color={GREEN_HIGHLIGHT}
                                />
                              </div>
                            </If>
                            <div
                              className={`${
                                checked && "bg-primary-purple"
                              } rounded-md w-7 h-7 border-2 m-2 mt-1
                            `}
                            ></div>
                          </div>
                        </div>
                        <div className="pt-2">{text}</div>
                      </div>
                    </>
                  );
                })}
              </div>
            </Accordion>
          );
        })}
        <div>
          {question.actions?.map(({ text, step_id, type, leftIcon }) => (
            <Button
              {...{ loading }}
              key={type}
              icon={leftIcon && icons[leftIcon as keyof typeof icons]}
              className="w-10/12 mx-auto my-5"
              secondary={type !== "prepare_for_meeting"}
              title={text}
              onClick={() => {
                onAnswer({
                  payload: state.map(({ id, category, text }) => ({
                    id,
                    category,
                    text,
                  })),
                  action: type,
                  stepId: step_id as any,
                });
              }}
            />
          ))}
        </div>
      </div>
    </QuestionWrapper>
  );
};

export default CheckBoxCategories;
