import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IGraph, RecommendationsQuestion, SetQuestionType } from "types";
import { DashboardFormWrapper } from "components/Dashboard/DashboardFormWrapper";
import FormInput from "components/Dashboard/FormInput";
import GraphPicker from "components/GraphPicker";
import If from "components/If";
import RectangleButton from "components/RectangleButton";
interface Props {
  question: Partial<RecommendationsQuestion>;
  setQuestion: SetQuestionType<RecommendationsQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
}

const RecommendationsForm = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  useEffect(() => {
    if (!question.actions?.length) {
      setQuestion((p) => {
        p.actions = [
          {
            type: "submit",
            text: "submit",
          },
        ];
      });
    }
  }, [setQuestion, question]);
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        title: "",
        description: "",
        id: (p?.questions?.length || 0) + 1,
        answers: [],
      };
      if (p.questions) {
        p.questions.push(val);
      } else {
        p.questions = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.questions!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Question" }}>
      <div className="mt-20 text-primary-blue">
        <div className="flex flex-col">
          <FormInput
            value={question["feedback"] || ""}
            placeholder={`Enter question feedback `}
            onChange={(e) => {
              setQuestion((p) => {
                p["feedback"] = e.target.value;
              });
            }}
          />
          {(question.questions || []).map((question, i) => (
            <div key={i} className="flex gap-3 items-center mb-10">
              <div>
                <FormInput
                  value={question["title"]}
                  placeholder={`Enter question number ${i + 1} title`}
                  onChange={(e) => {
                    setQuestion((p) => {
                      p.questions![i]["title"] = e.target.value;
                    });
                  }}
                />
                {/* TODO separate from here v */}
                <FormInput
                  value={question["description"]}
                  placeholder={`Enter question number ${i + 1} description`}
                  onChange={(e) => {
                    setQuestion((p) => {
                      p.questions![i]["description"] = e.target.value;
                    });
                  }}
                />
                <div
                  className="hover:bg-red-200 rounded-full p-1 cursor-pointer w-fit"
                  onClick={() => onRemove(i)}
                >
                  <AiOutlineClose color="red" />
                </div>
                {/* TODO seperate to here ^ */}
                {question.answers.map((ans, j) => (
                  <div className="my-4 flex items-center ">
                    <FormInput
                      value={ans["text"]}
                      placeholder={`Enter ans number ${j + 1} text`}
                      onChange={(e) => {
                        setQuestion((p) => {
                          p.questions![i].answers![j]!["text"] = e.target.value;
                        });
                      }}
                    />
                    <div
                      className="hover:bg-red-200 rounded-full p-1 cursor-pointer w-fit h-fit"
                      onClick={() =>
                        setQuestion((p) => {
                          p.questions![i].answers.splice(j, 1);
                        })
                      }
                    >
                      <AiOutlineClose color="red" />
                    </div>
                  </div>
                ))}
                <RectangleButton
                  title="Add answer"
                  className="w-fit mx-auto mt-1"
                  onClick={() =>
                    setQuestion((p) => {
                      p.questions![i]["answers"].push({
                        id: p.questions![i]["answers"].length + 1,
                        text: "",
                      });
                    })
                  }
                />
              </div>
            </div>
          ))}
        </div>
        <If cond={isDashboardInDevMode}>
          <GraphPicker
            {...{
              graph,
              loading: loadingGraph,
              title:
                question?.actions?.[0]?.step_id ||
                `Pick ${question?.actions?.[0].type} step id`,
              onPick: (id) =>
                setQuestion((q) => {
                  q.actions![0]!.step_id = id;
                }),
            }}
          />
        </If>
        <FormInput
          value={question?.actions?.[0].text}
          placeholder={`Enter button 1 text`}
          onChange={(e) => {
            setQuestion((p) => {
              p.actions![0]["text"] = e.target.value;
            });
          }}
        />
      </div>
    </DashboardFormWrapper>
  );
};

export default RecommendationsForm;
