import { getGraph } from "api";
import { useCallback, useEffect, useState } from "react";
import { IGraph } from "types";
import { useImmer } from "use-immer";
import { stringToColor } from "utils";

export const useGraph = () => {
  const [graph, setGraph] = useImmer({} as IGraph);
  const [loading, setLoading] = useState(true);

  const fetchGraph = useCallback(async () => {
    setLoading(true);
    const data = await getGraph();
    if (data) {
      data.nodes = data.nodes.map((n) => ({
        ...n,
        title: n.title.slice(0, 15),
        label: n.label.slice(0, 30),
        group: n.journey_name,
        color: stringToColor(n.journey_name),
      }));
      data.edges = data.edges.map((e) => ({
        ...e,
        color: stringToColor(e.journey_name),
      }));
      setGraph(data);
      setLoading(false);
    }
  }, [setGraph]);

  useEffect(() => {
    fetchGraph();
  }, [fetchGraph]);

  return { graph, refetchGraph: fetchGraph, loading };
};
