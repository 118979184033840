import { Answer, CardSizes, MultiQuestion } from "types";
import CardAnswer from "../CardAnswer";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: MultiQuestion;
  onAnswer: (answer: Answer) => void;
  loading: boolean;
}
const SimpleQuestion = ({
  onAnswer,
  question: { answers, headline, description, header },
  loading,
}: Props) => {
  const size = () => {
    switch (answers.length) {
      case 1:
        return CardSizes.Xl;
      case 2:
        return CardSizes.Large;
      case 3:
        return CardSizes.Regular;
      default:
        return CardSizes.Small;
    }
  };
  return (
    <QuestionWrapper {...{ headline, description, thumbnail: header }}>
      <div className="flex w-full flex-col gap-4 items-center">
        {answers?.map(({ text, id, image, step_id }) => (
          <CardAnswer
            size={size()}
            key={`answer-${id}`}
            {...{ text, id, step_id, onAnswer, loading, image }}
          />
        ))}
      </div>
    </QuestionWrapper>
  );
};

export default SimpleQuestion;
