import { ReactNode } from "react";
import { Bullet as BulletType } from "types";
import Block from "./Block";
import If from "./If";

interface Props {
  bullet: BulletType;
  children?: ReactNode | ReactNode[];
}
const Bullet = ({ bullet, children }: Props) => {
  return (
    <div className="w-full flex-col flex text-primary-blue p-5 rounded-[16px] bg-white">
      <Block text={bullet.bullet} />
      <If cond={!!bullet?.headline}>
        <h2 className="text-xl  font-medium  mb-5 font-ubuntu-medium">
          {bullet?.headline}
        </h2>
      </If>
      <p className="text-md">{bullet.text}</p>
      {children}
    </div>
  );
};

export default Bullet;
