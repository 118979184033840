import { WritableDraft } from "immer/dist/internal";
import { ChangeEvent } from "react";
import { classes, FIELDS } from "pages/Dashboard/dashboardUtils";
import { Question, SetQuestionType } from "types";
import If from "../If";

interface Props {
  question: Partial<Question>;
  setQuestion: SetQuestionType<Question>;
}

export const FormFields = ({ question, setQuestion }: Props) => {
  return (
    <>
      {FIELDS.map((field) => {
        const placeholder = `Enter ${field.key.split("_").join(" ")}`;
        const onChange = (
          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) =>
          setQuestion((p) => {
            const { value } = e.target;
            p[field.key as keyof WritableDraft<Partial<Question>>] = (
              field.type === "number" ? +value : e.target.value
            ) as any;
          });

        const value =
          (question?.[field.key as keyof Question] as string) ||
          // @ts-ignore
          field.default ||
          "";

        return (
          <If cond={field.cond(question)} key={field.key}>
            <span className="label-text ml-1">
              {field.key.split("_").join(" ")}:
            </span>
            <If cond={["text", "number"].includes(field.type)}>
              <input
                {...{
                  value,
                  placeholder,
                  onChange,
                  className: classes.input,
                }}
                type={field.type}
                required={field.required}
              />
            </If>
            <If cond={field.type === "textarea"}>
              <>
                <If cond={field.key === "description"}>
                  <div className="ml-1">
                    For bold wrap your text with [b]* [b]
                  </div>
                </If>
                <textarea
                  {...{
                    value,
                    placeholder,
                    onChange,
                    className: `${classes.input} pt-3`,
                  }}
                  required={field.required}
                />
              </>
            </If>
          </If>
        );
      })}
    </>
  );
};
