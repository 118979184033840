import { ReactNode } from "react";
import { InjectedBoldText } from "utils/text";

interface Props {
  icon: string | ReactNode;
  text: string;
  bg: string;
}

const IconCard = ({ icon, text, bg }: Props) => {
  return (
    <div className="bg-white mb-3 rounded-[16px] p-5  border border-w-1">
      <div className="p-1 py-0 text-ellipsis overflow-hidden ">
        <div className="flex items-center">
          <div
            style={{ backgroundColor: bg }}
            className={`rounded-full w-16 min-w-[4rem] h-16  mr-3 flex items-center justify-center `}
          >
            {typeof icon === "string" ? <img src={icon} alt="" /> : icon}
          </div>
          <span className="text-primary-blue font-heebo text-md md:text-xl p-1">
            <InjectedBoldText text={text} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default IconCard;
