import { Maybe } from "./";

export enum HeaderType {
  Image = "image",
  TitleImage = "title_image",
}
export interface Header {
  type: HeaderType;
  title?: string;
  image?: string;
}

export interface QuestionBase {
  header?: Header;
  journey_id: number;
  component_id: number;
  step_id: number | string;
  type: QuestionType;
  step_number: number;
  number_of_steps: number;
  default_step: Maybe<string>;
  journey_name: string;
  chat_id: string;
  headline: string;
  communication_type?: string;
}

export interface BlueTipDotsListQuestionSectionPayload {
  title: string;
  dots: TextAndId[];
}
export interface BlueTipDotsListQuestionSection {
  title: string;
  description: string;
  payload: BlueTipDotsListQuestionSectionPayload[];
}
export interface BlueTipDotsListQuestion extends QuestionBase {
  description?: string;
  sections: BlueTipDotsListQuestionSection[];
}

export interface PopupQuestionType extends Omit<QuestionBase, "headline"> {
  actions: Action[];
  title: string;
  text: string;
}

export enum CommunicationType {
  Web = "web",
  Whatsapp = "whatsapp",
}
export interface WhatsappStepLink
  extends Omit<
    QuestionBase,
    | "header"
    | "journey_id"
    | "component_id"
    | "step_number"
    | "number_of_steps"
    | "default_step"
    | "journey_name"
    | "chat_id"
  > {
  next_step_id: string;
}
export interface ChatMessage {
  from: string;
  text: string;
}
export interface Section {
  title: string;
  description: string;
  chat: ChatMessage[];
}
export interface ChatPresentationQuestion extends QuestionBase {
  sections: Section[];
  description?: string;
}

export interface RecommendationAnswer {
  text: string;
  id: number | string;
  suggest?: boolean;
}
export interface RecommendationAnswerSingle extends RecommendationAnswer {
  questionId: string;
}
export interface Recommendation {
  title: string;
  description?: string;
  answers: RecommendationAnswer[];
  id: number | string;
}
export interface RecommendationsQuestion extends QuestionBase {
  description?: string;
  questions: Recommendation[];
  feedback?: string;
  actions: Action[];
}

export interface Identification {
  text: string;
  icon: string;
}
export interface InformationQuestion extends QuestionBase {
  description?: string;
  identification_description?: string;
  button_text: string;
  identifications: Identification[];
}
export interface CheckBoxCategory {
  title: string;
  icon: string;
  id: number | string;
  checkboxes: Omit<CheckBoxCategoriesAnswerSingle, "category">[];
}
// TODO implement in all questions related
export interface TextAndId {
  id: number | string;
  text: string;
}
export interface UserQuestionsListCategory {
  title: string;
  icon: string;
  id: number | string;
  data: TextAndId[];
}
export interface UserQuestionsListQuestion extends QuestionBase {
  description?: string;
  categories: UserQuestionsListCategory[];
  actions: Action[];
}
export interface CheckBoxCategoriesQuestion extends QuestionBase {
  description?: string;
  categories: CheckBoxCategory[];
  actions: Action[];
}
export interface BodyMapQuestion extends QuestionBase {
  description?: string;
}

export interface InnerStep {
  headline: string;
  description: string;
}
export interface TutorialQuestion extends QuestionBase {
  inner_steps: InnerStep[];
  button_text: string;
}

export interface NextQuestion extends QuestionBase {
  description?: string | null;
  image?: string;
  button_text: string;
}

export interface MultiQuestion extends QuestionBase {
  description?: string | null;
  answers: SimpleAnswer[];
}

export interface MultiSliderSingle {
  description?: string;
  min_value?: string;
  max_value?: string;
  min_range: number;
  max_range: number;
  title?: string;
  id?: number;
  mid_value?: string;
  blue_title?: string;
  tips?: Record<string, string>;
}
export interface SliderQuestion extends QuestionBase {
  description: string;
  min_value: string;
  mid_value: string;
  max_value: string;
  min_range: number;
  max_range: number;
  tips?: Record<string, string>;
}
export interface MultiSliderQuestion extends QuestionBase {
  sliders: MultiSliderSingle[];
  description?: string | null;
}

export interface SimpleAnswer {
  text: string;
  id: number;
  image?: string;
  step_id?: string;
}

export interface NextAnswer {
  answered: boolean;
}
export interface MultiSliderAnswer extends MultiSliderSingle {
  answered: number;
}

export interface BlueTipDotsListQuestionWithAnswer
  extends BlueTipDotsListQuestion {
  answered: boolean;
}
export interface MultiSliderQuestionWithAnswer extends MultiSliderQuestion {
  answered: MultiSliderAnswer[];
}
export interface NextQuestionWithAnswer extends NextQuestion {
  answered: boolean;
}

export interface TutorialQuestionWithAnswer extends TutorialQuestion {
  answered: boolean;
}

export interface ChatPresentationQuestionWithAnswer
  extends ChatPresentationQuestion {
  answered: boolean;
}
export interface InformationQuestionWithAnswer extends InformationQuestion {
  answered: boolean;
}
export interface CustomCategory {
  title: string;
  id: number;
  icon: string;
}

export interface CheckBoxAnswer {
  title: string;
  id: number;
  text: string;
}
export interface CheckBoxCategoriesAnswerSingle {
  id: number | string;
  text: string;
  category: string | number;
}

export interface CheckBoxCategoriesAnswer {
  payload: CheckBoxCategoriesAnswerSingle | CheckBoxCategoriesAnswerSingle[];
  action: ActionType;
  step_id?: string | number;
}
export interface CheckBoxOptions {
  title: string;
  id: number;
  text: string;
  edit?: boolean;
}
export interface CustomAnswer {
  category: Omit<CustomCategory, "icon">;
  text: string;
  step_id?: string;
}

export type Answer =
  | SimpleAnswer
  | Record<string, number> //bodymap
  | number
  | CustomAnswer
  | MultiSliderAnswer[]
  | Seen
  | RecommendationAnswerSingle[]
  | CheckBoxAnswer[]
  | boolean
  | CheckBoxCategoriesAnswer
  | CheckBoxCategoriesAnswer[]
  | UserListAnswer
  | CheckBoxAnswer;

export enum WhatsappQuestionType {
  WhatsappStepLink = "whatsapp_step_link",
  WhatsappMultiChoices = "whatsapp_multi_choices",
}

export enum WebQuestionType {
  Slider = "slider",
  Regular = "multi_choices",
  Custom = "open_text_category",
  BluTipQuestion = "multi_blue_tip",
  CheckBox = "checkbox_group",
  MultiSlider = "multi_slider",
  Next = "next",
  Tutorial = "tutorial",
  BodyMap = "body_map",
  Information = "information",
  CheckBoxCategories = "checkbox_categories",
  Recommendations = "recommendations",
  Popup = "popup",
  ChatPresentation = "chat_presentation",
  UserQuestionList = "user_input_summary",
  BlueTipDotsList = "blue_tip_dots_list",
}
// TODO find better solutions
export enum QuestionType {
  Slider = "slider",
  Regular = "multi_choices",
  Custom = "open_text_category",
  BluTipQuestion = "multi_blue_tip",
  CheckBox = "checkbox_group",
  MultiSlider = "multi_slider",
  Next = "next",
  Tutorial = "tutorial",
  BodyMap = "body_map",
  Information = "information",
  CheckBoxCategories = "checkbox_categories",
  Recommendations = "recommendations",
  Popup = "popup",
  ChatPresentation = "chat_presentation",
  UserQuestionList = "user_input_summary",
  BlueTipDotsList = "blue_tip_dots_list",
  WhatsappStepLink = "whatsapp_step_link",
  WhatsappMultiChoices = "whatsapp_multi_choices",
}

export enum CardSizes {
  Small = "small",
  Regular = "regular",
  Large = "large",
  Xl = "extra-large",
}

export interface CustomQuestion extends QuestionBase {
  categories: CustomCategory[];
  actions: Action[];
}

export interface Bullet {
  bullet: string;
  headline: string;
  text: string;
  id: number;
}
export interface BluTipQuestion extends QuestionBase {
  bullets: Bullet[];
  description?: string;
}

export interface CheckBoxQuestion extends QuestionBase {
  answers: CheckBoxOptions[];
  description?: string;
}

export interface CheckBoxQuestionWithAnswer extends CheckBoxQuestion {
  answered: CheckBoxAnswer[];
}
export interface PopupQuestionWithAnswer extends PopupQuestionType {
  answered: boolean;
}

export interface CheckBoxCategoriesQuestionWithAnswer
  extends CheckBoxCategoriesQuestion {
  answered: CheckBoxCategoriesAnswer[];
}
export interface UserListAnswer {
  action: ActionType;
  step_id?: string;
}
export interface UserQuestionsListQuestionWithAnswer
  extends UserQuestionsListQuestion {
  answered: UserListAnswer;
}
export interface RecommendationsQuestionWithAnswer
  extends RecommendationsQuestion {
  answered: RecommendationAnswerSingle[];
}

export interface BodyMapWithAnswer extends BodyMapQuestion {
  answered: Record<string, number>;
}
export interface Seen {
  seen: boolean;
}
export interface BluTipQuestionWithAnswer extends BluTipQuestion {
  answered: Seen;
}

export interface MultiQuestionWithAnswer extends MultiQuestion {
  answered: SimpleAnswer;
}
export interface SliderQuestionWithAnswer extends SliderQuestion {
  answered: number | string;
}

export interface CustomQuestionWithAnswer extends CustomQuestion {
  answered: CustomAnswer;
}

export type Question =
  | SliderQuestion
  | MultiQuestion
  | CustomQuestion
  | CheckBoxQuestion
  | MultiSliderQuestion
  | NextQuestion
  | TutorialQuestion
  | BodyMapQuestion
  | InformationQuestion
  | CheckBoxCategoriesQuestion
  | RecommendationsQuestion
  | ChatPresentationQuestion
  | PopupQuestionType
  | UserQuestionsListQuestion
  | BlueTipDotsListQuestion
  | BluTipQuestion;

export type QuestionWithAnswer =
  | SliderQuestionWithAnswer
  | MultiQuestionWithAnswer
  | BluTipQuestionWithAnswer
  | CheckBoxQuestionWithAnswer
  | MultiSliderQuestionWithAnswer
  | NextQuestionWithAnswer
  | TutorialQuestionWithAnswer
  | BodyMapWithAnswer
  | InformationQuestionWithAnswer
  | CheckBoxCategoriesQuestionWithAnswer
  | RecommendationsQuestionWithAnswer
  | PopupQuestionWithAnswer
  | ChatPresentationQuestionWithAnswer
  | UserQuestionsListQuestionWithAnswer
  | BlueTipDotsListQuestionWithAnswer
  | CustomQuestionWithAnswer;

export type ActionType =
  | "another_one"
  | "review"
  | "submit"
  | "add_question"
  | "edit_list"
  | "prepare_for_meeting";

export interface Action {
  type: ActionType;
  text: string;
  step_id?: string;
  leftIcon?: string;
}

// TODO remove types
// TODO add left icon
// TODO toggle primary

export type SetQuestionType<T> = (q: (t: Partial<T>) => void) => void;
