//TODO use IS_DEV to control logs
class Logger {
  error(...message: any) {
    console.error(...message);
  }
  log(...message: any) {
    console.log(...message);
  }
  warn(message: any) {
    console.warn(...message);
  }
}

export const logger = new Logger();
