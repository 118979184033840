import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CustomQuestion, IGraph, SetQuestionType } from "types";
import { DashboardFormWrapper } from "components/Dashboard/DashboardFormWrapper";
import FormInput from "components/Dashboard/FormInput";
import GraphPicker from "components/GraphPicker";
import IconFormPicker from "components/Dashboard/IconFormPicker";
import If from "components/If";
interface Props {
  question: Partial<CustomQuestion>;
  setQuestion: SetQuestionType<CustomQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
}

export const CustomQuestionForm = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  useEffect(() => {
    if (!question.actions?.length) {
      setQuestion((p) => {
        p.actions = [
          {
            type: "another_one",
            text: "Save and add another question",
          },
          {
            type: "review",
            text: "Save and review list",
          },
        ];
      });
    }
  }, [setQuestion, question]);
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        title: "",
        icon: "",
        id: (p?.categories?.length || 0) + 1,
      };
      if (p.categories) {
        p.categories.push(val);
      } else {
        p.categories = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.categories!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Category" }}>
      <div className="mt-20 text-primary-blue">
        <div className="flex flex-col">
          {(question.categories || []).map((category, i) => (
            <div key={i} className="flex gap-3 items-center mb-10">
              <div>
                <input
                  type="text"
                  className="input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto"
                  value={category["title"]}
                  placeholder={`Enter category number ${i + 1} title`}
                  onChange={(e) => {
                    setQuestion((p) => {
                      p.categories![i]["title"] = e.target.value;
                    });
                  }}
                />
                <If cond={isDashboardInDevMode}>
                  <IconFormPicker
                    name="category"
                    idx={i}
                    currIcon={category.icon}
                    onChange={(icon) =>
                      setQuestion((q) => {
                        q.categories![i]!.icon = icon;
                      })
                    }
                  />
                </If>
              </div>
              <div
                className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
                onClick={() => onRemove(i)}
              >
                <AiOutlineClose color="red" />
              </div>
            </div>
          ))}
        </div>
        <If cond={isDashboardInDevMode}>
          <GraphPicker
            {...{
              graph,
              loading: loadingGraph,
              title:
                question?.actions?.[0]?.step_id ||
                `Pick ${question?.actions?.[0].type} step id`,
              onPick: (id) =>
                setQuestion((q) => {
                  q.actions![0]!.step_id = id;
                }),
            }}
          />
        </If>
        <FormInput
          value={question?.actions?.[0].text}
          placeholder={`Enter button 1 text`}
          onChange={(e) => {
            setQuestion((p) => {
              p.actions![0]["text"] = e.target.value;
            });
          }}
        />
        <If cond={isDashboardInDevMode}>
          <GraphPicker
            {...{
              graph,
              loading: loadingGraph,
              title:
                question?.actions?.[1]?.step_id ||
                `Pick ${question?.actions?.[1].type} step id`,
              onPick: (id) =>
                setQuestion((q) => {
                  q.actions![1]!.step_id = id;
                }),
            }}
          />
        </If>

        <FormInput
          value={question?.actions?.[1].text}
          placeholder={`Enter button 2 text`}
          onChange={(e) => {
            setQuestion((p) => {
              p.actions![1]["text"] = e.target.value;
            });
          }}
        />
      </div>
    </DashboardFormWrapper>
  );
};
