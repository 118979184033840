import { InjectedBoldText } from "utils/text";

interface Props {
  text: string;
  className?: string;
  alignLeft?: boolean;
  mb?: string;
}
const Paragraph = ({ text, className = "", alignLeft, mb }: Props) => {
  return (
    <p
      className={`
    font-heebo
    text-[19px]
    leading-[26px]
    mt-5
    whitespace-pre-line
    text-primary-blue
    break-words
    ${alignLeft ? "text-left" : "text-center"}
    ${mb ? mb : "mb-12"}
    ${className}
    `}
    >
      <InjectedBoldText {...{ text }} />
    </p>
  );
};

export default Paragraph;
