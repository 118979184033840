import { motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import If from "./If";

interface Props {
  iconBg?: string;
  icon?: string | ReactNode;
  title: string;
  children?: ReactNode | ReactNode[];
  customElement?: ReactNode | ReactNode[];
  customLeftElement?: ReactNode | ReactNode[];
  className?: string;
  hideArrow?: boolean;
  titleContainerClass?: string;
  titleClass?: string;
  childrenContainerClass?: string;
}

const Accordion = ({
  className,
  iconBg,
  icon,
  title,
  children,
  customElement,
  customLeftElement,
  hideArrow,
  titleClass = "",
  titleContainerClass = "",
  childrenContainerClass = "",
}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <motion.div
      className={`${className} text-primary-blue bg-white border p-4  rounded-[16px] `}
    >
      <div
        className="flex items-center relative cursor-pointer"
        onClick={() => setOpen((p) => !p)}
      >
        <If cond={!!icon}>
          <div>
            <div
              style={{ backgroundColor: iconBg }}
              className={`rounded-full w-16 min-h-16 mx-3 flex items-center justify-center `}
            >
              {typeof icon === "string" ? <img src={icon} alt="" /> : icon}
            </div>
          </div>
        </If>

        <div className={`relative ${titleContainerClass}`}>
          {customLeftElement}
          <div className={`font-heebo-bold ${titleClass}`}>{title}</div>
          {customElement}
        </div>
        <If cond={!hideArrow}>
          <motion.div
            animate={{
              transform: `rotate(${!open ? 0 : 180}deg)`,
              transition: {
                ease: "linear",
              },
            }}
            className="mr-5 ml-auto"
          >
            <AiOutlineDown />
          </motion.div>
        </If>
      </div>
      {open && (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          className={`mt-5 ${childrenContainerClass}`}
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {children}
        </motion.div>
      )}
    </motion.div>
  );
};

export default Accordion;
