import Questions from "./pages/Questions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import ErrorBoundary from "components/Errors/ErrorBoundary";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          }
        />
        <Route path="*" element={<Questions />} />
      </Routes>
    </Router>
  );
}

export default App;
