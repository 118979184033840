import { useEffect, useRef, useState } from "react";
import Container from "components/Container";
import DashboardQuestionViewer from "components/DashboardQuestionViewer";
import If from "components/If";
import RectangleButton from "components/RectangleButton";
import { Question, QuestionType, SliderQuestion } from "types";
import { BG } from "utils";
import uuid from "react-uuid";
import { useImmer } from "use-immer";
import { addStep, deleteStep, getAllSteps, updateStep } from "api";
import { classes } from "./dashboardUtils";
import DashboardFormRenderer from "components/Dashboard/DashboardFormRenderer";
import SuccessMessage from "components/SuccessMessage";
import {
  DashboardToolBar,
  FormFields,
  JourneyPicker,
} from "components/Dashboard";
import { QuestionTypePicker } from "components/Dashboard";
import { QuestionHeaderPicker } from "components/Dashboard";
import { ViewMenu } from "components/Dashboard";
import { IoIosTrash } from "react-icons/io";
import { WritableDraft } from "immer/dist/internal";
import Graph from "components/Graph/Graph";
import { useGraph } from "hooks/useGraph";
import GraphPicker from "components/GraphPicker";
import { Page, useFavicon } from "hooks";
import { isDashboardInDevMode } from "bin";
import discoveryPng from "assets/logos/discovery.png";

export type Mode = "editor" | "view";

const Dashboard = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [question, setQuestion] = useImmer<Partial<Question>>({});
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [password, setPassword] = useState("");
  const [questionViewMode, setQuestionViewMode] = useState<Question>(
    {} as Question
  );
  const [mode, setMode] = useState<Mode>("editor");
  const [questionMode, setQuestionMode] = useState<"update" | "add">("add");
  const [controllerShown, setControllerShown] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [flow, setFlow] = useImmer<Partial<Question>[]>([]);
  const [steps, setSteps] = useImmer<Partial<Question>[]>([]);
  const [loading, setLoading] = useState(false);
  const [journeyList, setJourneyList] = useState<string[]>([]);
  const [currJourney, setCurrJourney] = useState("onboarding");
  const { graph, loading: loadingGraph, refetchGraph } = useGraph();
  useFavicon(Page.Dashboard);

  useEffect(() => {
    (async () => {
      const steps = await getAllSteps();
      if (steps) {
        setSteps(steps);
        // @ts-ignore
        setJourneyList([...new Set(steps.map((s) => s.journey_name))]);
      }
    })();
  }, [setSteps]);
  useEffect(() => {
    setFlow(steps.filter((step) => step.journey_name === currJourney));
  }, [steps, currJourney, setFlow]);

  useEffect(() => {
    if (question?.type === QuestionType.Slider) {
      setQuestion((q) => {
        (q as WritableDraft<SliderQuestion>).min_range = 1;
        (q as WritableDraft<SliderQuestion>).max_range = 10;
      });
    }
  }, [question?.type, setQuestion]);

  useEffect(() => {
    if (Object.keys(question).length && !question?.step_id) {
      const id = uuid();
      setQuestion((p) => {
        p.step_id = id;
      });
      if (
        flow.length &&
        flow.length > (question.step_number! ?? Infinity) - 1
      ) {
        setFlow((prev) => {
          prev[question.step_number! - 1].step_id = id;
        });
      }
    }
    if (questionMode === "update") return;
    setQuestion((p) => {
      p.step_number = flow.length + 1;
      p.number_of_steps = flow.length + 1;
      p.journey_name = currJourney;
    });
  }, [
    currJourney,
    flow.length,
    setFlow,
    setQuestion,
    question,
    questionMode,
    question?.step_id,
    flow,
  ]);

  const showAlert = () => {
    setAlertShown(true);
    setTimeout(() => {
      setAlertShown(false);
    }, 4000);
  };
  const graphEvents = {
    select: (event: any) => {
      const { nodes } = event;
      if (nodes.length === 1) {
        const [id] = nodes;
        setQuestion(
          () => steps.find((step) => step.step_id === id) as Partial<Question>
        );
        setQuestionMode("update");
        setControllerShown(false);
      }
    },
  };

  if (!passwordCorrect) {
    return (
      <div
        style={{ background: BG }}
        className="w-screen h-screen flex justify-center items-center flex-col "
      >
        <input
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          placeholder="password"
          className="input"
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              setPasswordCorrect(
                password === process.env.REACT_APP_DASHBOARD_PASS
              );
            }
          }}
        />
        <RectangleButton
          title="Submit"
          className="py-[13px] my-2"
          onClick={() =>
            setPasswordCorrect(
              password === process.env.REACT_APP_DASHBOARD_PASS
            )
          }
        />
      </div>
    );
  }

  return (
    <>
      <DashboardToolBar
        {...{
          question,
          setQuestion,
          questionMode,
          setQuestionMode,
          setControllerShown,
          steps,
        }}
      />

      <Container className="flex w-full pb-20">
        <div className="h-full max-h-screen overflow-y-scroll w-[30%]  text-primary-blue p-4 pb-44 flex flex-col">
          <If cond={isDashboardInDevMode}>
            <div className={classes.wrap}>
              {["Editor", "View"].map((modeType) => (
                <div
                  onClick={() => {
                    setControllerShown(false);
                    setMode(modeType.toLowerCase() as Mode);
                  }}
                  className={`${classes.bullet} ${
                    mode === modeType.toLowerCase() && "bg-slate-700 text-white"
                  }`}
                  key={modeType}
                >
                  {modeType}
                </div>
              ))}
            </div>
          </If>
          <If cond={!isDashboardInDevMode}>
            <img
              src={discoveryPng}
              alt=""
              className="object-contain w-36 mt-[-30px]"
            />
          </If>
          <If cond={mode === "view"}>
            <ViewMenu {...{ loading, setLoading, setQuestionViewMode }} />
          </If>
          <If cond={questionMode === "update" && isDashboardInDevMode}>
            <div className="w-full flex justify-center mb-8">
              <IoIosTrash
                color="red"
                size={30}
                onClick={async () => {
                  if (question.step_id) {
                    const data = await deleteStep(question.step_id as string);
                    if (data) {
                      const steps = await getAllSteps();
                      if (steps) {
                        setSteps(steps);
                        setQuestion({});
                        setQuestionMode("add");
                      }
                    }
                  }
                }}
                className="hover:bg-red-200 rounded-full cursor-pointer"
              />
            </div>
          </If>
          <If cond={mode === "editor"}>
            <>
              <If cond={isDashboardInDevMode}>
                <JourneyPicker
                  {...{
                    journeyList,
                    setQuestion,
                    setCurrJourney,
                    setJourneyList,
                  }}
                  selectedJourney={question.journey_name}
                />
              </If>
              <If cond={isDashboardInDevMode}>
                <QuestionTypePicker {...{ question, setQuestion }} />
              </If>
              <div className="relative">
                <If cond={isDashboardInDevMode}>
                  <GraphPicker
                    {...{
                      graph,
                      loading: loadingGraph,
                      title: question?.default_step || undefined,
                      onPick: (id) =>
                        setQuestion((q) => {
                          q.default_step = id;
                        }),
                    }}
                  />
                </If>

                <If cond={isDashboardInDevMode}>
                  <If cond={!!question?.default_step}>
                    <div
                      onClick={() =>
                        setQuestion((q) => {
                          q.default_step = null;
                        })
                      }
                      className={classes.x}
                    >
                      X
                    </div>
                  </If>
                </If>
              </div>
              <If cond={isDashboardInDevMode}>
                <QuestionHeaderPicker {...{ question, setQuestion }} />
              </If>
              <FormFields {...{ question, setQuestion }} />
              <DashboardFormRenderer
                {...{ question, setQuestion, graph, loadingGraph }}
              />

              <div className="flex">
                {[
                  {
                    title: questionMode === "add" ? "Add" : "Update",
                    onClick: async () => {
                      if (questionMode === "update") {
                        const updated = await updateStep({
                          step_id: question.step_id! as string,
                          update: { ...question },
                        });

                        if (updated) {
                          showAlert();
                          const steps = await getAllSteps();
                          if (steps) {
                            setSteps(steps);
                            setFlow(
                              steps.filter(
                                (step) => step.journey_name === currJourney
                              )
                            );
                          }
                        }
                      } else {
                        const isRoot = flow.length === 0;
                        const step = await addStep(
                          question as Question,
                          isRoot
                        );
                        if (step) {
                          showAlert();
                          setFlow((prev) => {
                            prev.splice(question.step_number! - 1, 0, question);
                          });
                          setFlow((prev) => {
                            for (let i = 0; i < prev.length; i++) {
                              prev[i].step_number = i + 1;
                              prev[i].number_of_steps = prev.length;
                            }
                          });
                          setQuestion({});
                        }
                      }
                      await refetchGraph();
                      // TODO: move to separate function
                      const steps = await getAllSteps();
                      if (steps) {
                        setSteps(steps);
                        setJourneyList([
                          // @ts-ignore
                          ...new Set(steps.map((s) => s.journey_name)),
                        ]);
                      }
                    },
                  },
                  isDashboardInDevMode
                    ? {
                        title: "Copy json",
                        onClick: () =>
                          navigator.clipboard.writeText(
                            JSON.stringify(question, null, 2)
                          ),
                      }
                    : null,
                ]
                  .filter((e) => !!e)
                  .map((btn) => (
                    // @ts-ignore
                    <RectangleButton
                      {...btn}
                      key={uuid()}
                      className="mx-auto w-fit my-10"
                    />
                  ))}
              </div>
              <If cond={isDashboardInDevMode}>
                <pre>{JSON.stringify(question, null, 2)}</pre>
              </If>
            </>
          </If>
        </div>
        <div className="w-[70%] h-full">
          <If cond={!!question && !controllerShown}>
            <DashboardQuestionViewer
              fromDashboard={true}
              headerClassName="w-[70%]"
              {...{
                scrollRef,
                loading: mode === "view" && loading,
                question: mode === "view" ? questionViewMode : question,
              }}
            />
          </If>
          <If cond={controllerShown}>
            <Graph events={graphEvents} {...{ graph, loading: loadingGraph }} />
          </If>
        </div>
      </Container>
      <If cond={alertShown}>
        <SuccessMessage
          msg={`${questionMode === "add" ? "Added" : "Updated"} successfully!`}
        />
      </If>
    </>
  );
};

export default Dashboard;
