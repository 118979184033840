import { useCallback, useEffect, useState } from "react";
import { createChat, proceedChatWithAnswer } from "api";
import { Answer, Question, QuestionWithAnswer } from "types";
import { getId } from "utils";
import { useQuery } from "./useQuery";

export const useChat = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [question, setQuestion] = useState({} as Question);
  const [history, setHistory] = useState([] as QuestionWithAnswer[]);
  const query = useQuery();

  useEffect(() => {
    (async () => {
      const journeyName = query.get("journey_name");
      const chat = await createChat(getId(), journeyName);
      if (!chat) {
        setError(true);
      } else {
        setQuestion(chat);
      }
      setLoading(false);
    })();
  }, [query]);

  const onAnswer = useCallback(
    async (answered: Answer) => {
      setLoading(true);
      setHistory(
        (prev) => [...prev, { ...question, answered }] as QuestionWithAnswer[]
      );
      const newQuestion = await proceedChatWithAnswer({
        ...question,
        answered,
      } as QuestionWithAnswer);

      if (!newQuestion) {
        setError(true);
      } else {
        setQuestion(newQuestion);
      }
      setLoading(false);
    },
    [question]
  );

  const deleteLastAnswer = useCallback(() => {
    if (!history.length) return;
    setLoading(true);
    const lastQuestion = history[history.length - 1] as QuestionWithAnswer;
    if (lastQuestion.answered) {
      // @ts-ignore
      delete lastQuestion.answered;
    }
    setQuestion(lastQuestion);
    setHistory((prev) => prev.slice(0, history.length - 1));
    setLoading(false);
  }, [history]);

  return {
    loading,
    error,
    question,
    history,
    onAnswer,
    deleteLastAnswer,
  };
};
