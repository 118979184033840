import { GiHeartInside, GiConversation } from "react-icons/gi";
import { CiMedicalClipboard, CiFaceSmile } from "react-icons/ci";
import { TfiHome } from "react-icons/tfi";
import { TbBed, TbClipboardPlus } from "react-icons/tb";
import { RiHandHeartLine } from "react-icons/ri";
import { IoLeafOutline } from "react-icons/io5";
const ICON_SIZE = 42;
enum IconColorPallet {
  Orange = "#FFE5B1",
  Blue = "#a5d1e3",
  Red = "#FFCFC0",
  Green = "#D0F1BF",
  Purple = "#EAEFFF",
  Turquoise = "#EAEFFF",
}

const ICON_PROPS = { size: ICON_SIZE };

export const CUSTOM_QUESTIONS_ICONS = {
  heartIcon: {
    icon: <GiHeartInside {...ICON_PROPS} />,
    bg: IconColorPallet.Orange,
  },
  leafIcon: {
    icon: <IoLeafOutline {...ICON_PROPS} />,
    bg: IconColorPallet.Orange,
  },
  sunHoldingIcon: {
    icon: <TbClipboardPlus {...ICON_PROPS} />,
    bg: IconColorPallet.Blue,
  },

  conversionIcon: {
    icon: <GiConversation {...ICON_PROPS} />,
    bg: IconColorPallet.Turquoise,
  },

  checkListIcon: {
    icon: <CiMedicalClipboard {...ICON_PROPS} />,
    bg: IconColorPallet.Purple,
  },
  smileIcon: {
    icon: <CiFaceSmile {...ICON_PROPS} />,
    bg: IconColorPallet.Purple,
  },
  homeIcon: {
    icon: <TfiHome {...ICON_PROPS} />,
    bg: IconColorPallet.Green,
  },
  bedIcon: {
    icon: <TbBed {...ICON_PROPS} />,
    bg: IconColorPallet.Red,
  },
  handsIcon: {
    icon: <RiHandHeartLine {...ICON_PROPS} />,
    bg: IconColorPallet.Red,
  },
};
