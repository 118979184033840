import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import uuid from "react-uuid";
import { CheckBoxCategoriesQuestion, IGraph, SetQuestionType } from "types";
import { DashboardFormWrapper } from "components/Dashboard/DashboardFormWrapper";
import FormInput from "components/Dashboard/FormInput";
import GraphPicker from "components/GraphPicker";
import IconFormPicker from "components/Dashboard/IconFormPicker";
import If from "components/If";
import RectangleButton from "components/RectangleButton";
interface Props {
  question: Partial<CheckBoxCategoriesQuestion>;
  setQuestion: SetQuestionType<CheckBoxCategoriesQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
}

const CheckBoxCategoriesForm = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  useEffect(() => {
    if (!question.actions?.length) {
      setQuestion((p) => {
        p.actions = [
          {
            type: "add_question",
            text: "Add a question",
            leftIcon: "plus",
          },
          {
            type: "prepare_for_meeting",
            text: "Prepare ",
          },
        ];
      });
    }
  }, [setQuestion, question]);
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        icon: "",
        title: "",
        id: uuid(),
        checkboxes: [],
      };
      if (p.categories) {
        p.categories.push(val);
      } else {
        p.categories = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.categories!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Category" }}>
      <>
        {(question.categories || []).map((category, i) => (
          <div key={i} className="flex gap-3 items-center mb-10">
            <div>
              <input
                type="text"
                className="input input-bordered w-full min-h-12 mt-8 min-w-[200px] mx-auto"
                value={category?.title}
                placeholder={`Enter category number ${i + 1} title`}
                onChange={(e) => {
                  setQuestion((p) => {
                    p.categories![i]!.title = e.target.value;
                  });
                }}
              />
              <If cond={isDashboardInDevMode}>
                <IconFormPicker
                  name="category"
                  idx={i}
                  currIcon={category.icon}
                  onChange={(icon) =>
                    setQuestion((q) => {
                      q.categories![i]!.icon = icon;
                    })
                  }
                />
              </If>

              <div>
                {category.checkboxes.map((c, idx) => (
                  <input
                    key={c.id}
                    className="input input-bordered w-full min-h-12 mt-8 min-w-[200px] mx-auto"
                    placeholder={`Enter checkbox number ${idx + 1}text`}
                    value={c.text}
                    onChange={(e) =>
                      setQuestion((q) => {
                        q.categories![i].checkboxes![idx]!.text =
                          e.target.value;
                      })
                    }
                  />
                ))}
              </div>
              <RectangleButton
                onClick={() =>
                  setQuestion((q) => {
                    q.categories![i].checkboxes.push({ text: "", id: uuid() });
                  })
                }
                title={`Add checkbox`}
                className="w-fit mx-auto my-10"
              />
            </div>
            <div
              className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
              onClick={() => onRemove(i)}
            >
              <AiOutlineClose color="red" />
            </div>
          </div>
        ))}
      </>
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[0]?.step_id ||
              `Pick ${question?.actions?.[0].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![0]!.step_id = id;
              }),
          }}
        />
      </If>

      <FormInput
        value={question?.actions?.[0].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![0]["text"] = e.target.value;
          });
        }}
      />
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[1]?.step_id ||
              `Pick ${question?.actions?.[1].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![1]!.step_id = id;
              }),
          }}
        />
      </If>
      <FormInput
        value={question?.actions?.[1].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![1]["text"] = e.target.value;
          });
        }}
      />
    </DashboardFormWrapper>
  );
};
export default CheckBoxCategoriesForm;
