import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { IGraph, PopupQuestionType, SetQuestionType } from "types";
import FormInput from "../FormInput";
import GraphPicker from "../../GraphPicker";
import If from "../../If";

interface Props {
  question: Partial<PopupQuestionType>;
  setQuestion: SetQuestionType<PopupQuestionType>;
  graph: IGraph;
  loadingGraph: boolean;
}

const PopupForm = ({ question, setQuestion, graph, loadingGraph }: Props) => {
  useEffect(() => {
    if (!question.actions?.length) {
      setQuestion((p) => {
        p.actions = [
          {
            type: "add_question",
            text: "Ok",
          },
        ];
      });
    }
  }, [setQuestion, question]);
  return (
    <>
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[0]?.step_id ||
              `Pick ${question?.actions?.[0].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![0]!.step_id = id;
              }),
          }}
        />
      </If>
      <FormInput
        value={question?.actions?.[0].text}
        placeholder={`Enter button 2 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![0]["text"] = e.target.value;
          });
        }}
      />
    </>
  );
};

export default PopupForm;
